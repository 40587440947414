<template>
  <ion-page>
      <div class="a-auth full-height" v-if="!logoutConfirmed">
        <div class="ion-text-center profile-wrapper profile" style="display: flex; justify-content: space-between; flex-direction: column;">
          <div class="m-width">
            <h1 class="mb-35">Profile</h1>
            <p>{{ user.username }}</p>
            <p class="username">{{user.email}}</p>
            <div class="small-divider"></div>
          </div>

          <div class="profile-bottom-wrapper">
            <div class="ion-padding-horizontal">
              <ion-button v-if="!logoutConfirmation" class="--default --danger" expand="block" @click="logOut()">Log out</ion-button>
              <ion-button v-else class="--default --danger-outline" expand="block" @click="logOutConfirm()">Confirm log out</ion-button>
            </div>
            <div class="ion-padding-horizontal">
              <ion-button v-if="!logoutConfirmation" class="--default --danger" expand="block" @click="deleteProfile()">Delete profile</ion-button>
            </div>

            <div class="ion-padding-top">
              <div class="version">App version: {{version}}</div>
            </div>
          </div>
        </div>
      </div>
  </ion-page>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {inject} from "vue"

  export default {
    setup() {
      const ionRouter = inject("navManager")

      return {
        ionRouter,
      }
    },
    data() {
      return {
        version: process.env.VUE_APP_VERSION,
        logoutConfirmation: false,
        logoutConfirmed: false
      }
    },
    computed: {
      ...mapGetters('wAuth', ['user']),
    },

    watch: {
      $route (to, from){
        this.logoutConfirmation = false
        if (to.fullPath == '/app/profile')
          this.logoutConfirmed = false
      }
    },

    methods: {
      logOut() {
        this.logoutConfirmation = true
      },

      logOutConfirm() {
        this.logoutConfirmed = true
        this.$store.dispatch('wAuth/logout')
        this.$router.push('login')
        window.location.reload()
      },

      deleteProfile() {
        this.ionRouter.navigate({
          routerDirection: "none",
          routerLink: "/app/delete-profile",
        })
      },
    }
  }
</script>

<style lang="sass" scoped>
.profile
  h1
    font-size: 26px
    margin: 0
    padding-top: 70px
    font-weight: bold
    color: black
  p
    margin: 0
    padding-bottom: 30px
    color: black
    &:first-child
      padding-bottom: 10px
  .image-wrapper
    padding-top: 30px
    img
      width: 30%
      padding: 0 10px
      margin: 5px 0
      &:first-child
        border-right: 1px solid rgba(154, 154, 154, 0.2)
  .small-divider
    width: 15%
    height: 1px
    margin: 0 auto
    background: black
    position: relative
    top: -1px
  .username
    font-weight: bold
    border-bottom: 1px solid rgba(154, 154, 154, 0.4)
.mb-35
    margin-bottom: 3.5rem !important

.version
  color: rgba(0,0,0, 0.3)
  font-size: 12px

.m-width
  width: 345px
  text-align: center
  margin: 0 auto
</style>
